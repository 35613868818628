import "../app/scss/common.scss";
import * as $ from 'jquery';
import 'bootstrap';
import 'moment';
import 'slick-carousel';
import 'select2';
import 'superfish';

import "../app/javascripts/library.js";
import "../app/javascripts/common.js";

import Utils from "../helpers/utils.js";
import { EnumStatus, EnumPosition, EnumQuestionType, EnumLanguage } from "../helpers/enum.js";

import EventEmitter from "events";
import Handlebars from "handlebars";

import Cookies from "js-cookie";

import RightCategoryTemplate from "../templates/right_category_temp.hbs";
import RightNewsTemplate from "../templates/right_news_temp.hbs";
import MediaTemplate from "../templates/media_temp.hbs";
import PollTemplate from "../templates/poll_temp.hbs";
import RightBannerTemplate from "../templates/right_banner_temp.hbs";


import './right_content_container.scss';

export default class RightContentContainer extends EventEmitter {
    constructor($container, options) {
        super();
        this.g_CurrentLanguage = Cookies.get('lang') || 'vi';
        this.g_$Container = $container;

        this.g_HideHighlightNews = options === undefined ? false : options.hideHighlightNews;
        this.g_HideLastestNews = options === undefined ? false : options.hideLastestNews;
        this.g_HideRightCategories = options === undefined ? false : options.hideRightCategories;
        this.g_HideMedia = options === undefined ? false : options.hideMedia;
        this.g_HidePollQuestion = options === undefined ? false : options.hidePollQuestion;

        this._initContainer();

        this._initEvents();
    }
    _initContainer() {
        let root = this;

        $.when(
            $.get("/api/tin-tuc/bai-viet/rightPositionNews?take=5"),
            $.get("/api/poll/randomPollQuestion"),
            $.get("/api/tin-tuc/album/randomMediaFiles?take=9"),
            $.get("/api/banner/items"))
            .then((newsResponse, pollQuestionResponse, mediaResponse, bannerResponse) => {
                let news = newsResponse[0];
                let pollQuestion = pollQuestionResponse[0];
                let medias = mediaResponse[0];
                let banner = bannerResponse[0];

                if (news.status == EnumStatus.OK) {
                    if (news.data) {
                        var rightCategories = news.data.rightPostionCategories;
                        var highlightNews = news.data.highlightNews;
                        var lastestNews = news.data.lastestNews;
                        if (!root.g_HideLastestNews) {
                            if (lastestNews.length > 0) {
                                lastestNews.map((v, i) => {
                                    v.anh_dai_dien = Utils.replaceSizeMedia(v.anh_dai_dien, "100x74");
                                })
                                root.g_$Container.append(Handlebars.compile(RightNewsTemplate)({
                                    title: this.g_CurrentLanguage == 'vi' ? "Tin tức mới" : "Latest news",
                                    listBaiViet: lastestNews
                                }))
                            }
                            if (banner.status == EnumStatus.OK) {
                                //banner.data.map((v, i) => {
                                //    v.avatar = Utils.replaceSizeMedia(v.avatar, "267x172");
                                //})
                                var filterBanner = banner.data.filter(x => x.position == EnumPosition.RIGHT && x.order_id <= 2);
                                if (filterBanner.length > 0) {
                                    root.g_$Container.append(Handlebars.compile(RightBannerTemplate)(filterBanner));
                                }
                            }
                        }
                        if (!root.g_HideHighLightNews) {
                            if (highlightNews.length > 0) {
                                highlightNews.map((v, i) => {
                                    v.anh_dai_dien = Utils.replaceSizeMedia(v.anh_dai_dien, "100x74");
                                })
                                root.g_$Container.append(Handlebars.compile(RightNewsTemplate)({
                                    title: this.g_CurrentLanguage == 'vi' ? "Tin nổi bật" : "Breaking news",
                                    url: "/tin-tuc/noi-bat",
                                    listBaiViet: highlightNews
                                }))
                            }
                        }
                        if (!root.g_HideRightCategories) {
                            if (rightCategories.length > 0) {
                                rightCategories.map((v, i) => {
                                    v.listBaiViet.map((vv, ii) => {
                                        if (ii == 0) {
                                            vv.anh_dai_dien = Utils.replaceSizeMedia(vv.anh_dai_dien, "388x268");
                                        } else {
                                            vv.anh_dai_dien = Utils.replaceSizeMedia(vv.anh_dai_dien, "192x128");
                                        }
                                    })
                                })
                                root.g_$Container.append(Handlebars.compile(RightCategoryTemplate)(rightCategories))
                            }
                        }
                    }
                }
                else {
                    Ultis.error(news.errors ? news.errors[0].message : "Lỗi vui lòng thử lại sau.")
                }

                //
                if (!root.g_HideMedia) {
                    if (medias.status == EnumStatus.OK) {
                        if (medias.data && medias.data.length) {
                            $.each(medias.data, function (idx, media) {
                                media.url = media.url.replace("media", "72x72");
                            })
                            root.g_$Container.append(Handlebars.compile(MediaTemplate)(medias.data))
                        }
                    }
                    else {
                        Ultis.error(medias.errors ? medias.errors[0].message : "Lỗi vui lòng thử lại sau.")
                    }
                }

                if (!root.g_HidePollQuestion) {
                    if (pollQuestion.status == EnumStatus.OK) {
                        if (pollQuestion.data) {
                            var question = pollQuestion.data;
                            question.allowMultiChoices = question.poll_question_type === EnumQuestionType.MULTIPLECHOICE;
                            root.g_$Container.append(Handlebars.compile(PollTemplate)(question))
                        }
                    }
                    else {
                        Ultis.error(pollQuestion.errors ? pollQuestion.errors[0].message : "Lỗi vui lòng thử lại sau.")
                    }
                }

                if (banner.status == EnumStatus.OK) {
                    var filterBanner = banner.data.filter(x => x.position == EnumPosition.RIGHT && x.order_id > 2);
                    if (filterBanner.length > 0) {
                        root.g_$Container.append(Handlebars.compile(RightBannerTemplate)(filterBanner));
                    }
                }
            });
    }
    _initEvents() {
        let root = this;

        $(document).on("click", ".btn-vote", (e) => {
            e.preventDefault();
            var $votedForm = $(".voted-form");
            var checkedChoices = $votedForm.find('input[name=voted]:checked');
            var data = [];
            if (checkedChoices.length > 0) {
                $.each(checkedChoices, function (key, element) {
                    data.push(parseInt($(element).attr('value')));
                });
                Utils.showProgress();
                $.ajax({
                    url: '/api/poll/votedChoices',
                    type: "POST",
                    data: { votedChoiceIds: data },
                    success: (xhr) => {
                        if (xhr.status == "OK") {
                            Utils.alert(`Xác nhận bình chọn thành công!`).then(() => {
                                $votedForm.find('input[name=voted]').prop('checked', false);
                            });
                        } else {
                            Utils.error(xhr.errors ? xhr.errors[0].message : "Lỗi vui lòng thử lại sau.")
                        }
                    },
                    error: (e) => {
                        Utils.error('Hệ thống gặp lỗi. Vui lòng thử lại sau.');
                    }
                })
            }
            else {
                Utils.error("Vui lòng chọn ít nhất 1 lựa chọn.");
            }
        })
    }
}
