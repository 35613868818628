import "../app/scss/common.scss";
import * as $ from 'jquery';
import 'bootstrap';
import 'moment';
import 'slick-carousel';
import 'select2';
import 'superfish';
import EventEmitter from "events";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox.js";
import Cookies from "js-cookie";

import "@fancyapps/ui/dist/fancybox.css";
import 'jquery-validation-unobtrusive/dist/jquery.validate.unobtrusive.min.js';
import 'jquery-ajax-unobtrusive/dist/jquery.unobtrusive-ajax.js';
// import "../../app/javascripts/jquery-3.5.1.min.js";
// import "../../app/javascripts/popper.min.js";
// import "../../app/javascripts/bootstrap.min.js";
// import "../../app/javascripts/moment-with-locales";
// import "../../app/javascripts/bootstrap-datetimepicker.min.js";
import "../app/javascripts/library.js";
// import "../../app/javascripts/slick.min.js";
import "../app/javascripts/common.js";

import Utils from "../helpers/utils.js";

import moment from "moment"
import Handlebars from "handlebars";
import MenuTemp from "../templates/menu.hbs";
import MenuWithChildrenTemp from "../templates/menu_with_children.hbs";
import ListMediaTemplate from "../templates/list_media_temp.hbs";
import MediaTemplate from "../templates/media_temp.hbs";
import PollTemplate from "../templates/poll_temp.hbs";
import RightCategoryTemplate from "../templates/right_category_temp.hbs";

import Localization from "./localization";

import { gettext, c, t } from "ttag";


import { EnumStatus, EnumPosition, EnumQuestionType } from "../helpers/enum.js";
export default class _Layout extends EventEmitter {
    constructor(mode) {
        super();

        Handlebars.registerHelper("toLocalDateString", (date) => {
            return moment(date).format("DD/MM/YYYY");
        });

        Handlebars.registerHelper("toLocalTimeString", (date) => {
            return moment(date).format("HH:mm");
        });

        Handlebars.registerHelper('ifCond', function (v1, operator, v2, options) {
            switch (operator) {
                case '==':
                    return (v1 == v2) ? options.fn(this) : options.inverse(this);
                case '===':
                    return (v1 === v2) ? options.fn(this) : options.inverse(this);
                case '!==':
                    return (v1 !== v2) ? options.fn(this) : options.inverse(this);
                case '<':
                    return (v1 < v2) ? options.fn(this) : options.inverse(this);
                case '<=':
                    return (v1 <= v2) ? options.fn(this) : options.inverse(this);
                case '>':
                    return (v1 > v2) ? options.fn(this) : options.inverse(this);
                case '>=':
                    return (v1 >= v2) ? options.fn(this) : options.inverse(this);
                case '&&':
                    return (v1 && v2) ? options.fn(this) : options.inverse(this);
                case '||':
                    return (v1 || v2) ? options.fn(this) : options.inverse(this);
                case 'contains':
                    return (v1.includes(v2)) ? options.fn(this) : options.inverse(this);
                default:
                    return options.inverse(this);
            }
        });

        Handlebars.registerHelper('isdefined', function (value) {
            return value !== undefined;
        });

        Handlebars.registerHelper('ttag', function (value) {
            return gettext(value);
        });

        //
        mode = mode || 'top';
        if (mode === 'top') {
            $('body').addClass('define-top');
        } else if (mode === 'child') {
            $('body').addClass('define-child');
        } else if (mode === 'map') {
            $('body').addClass('define-map');
        }
        this.g_$Footer = $("#footer");
        this.g_$FooterBellow = this.g_$Footer.find('.footer-bellow');
        this.g_$RightContent = $(".col-right-inner");
        this._initMenu();
        this._initFooter();
        this._eventLayout();

        let me = this;

        async function load() {
            // await me.getToken();
            //
            $.ajaxSetup({
                headers: {
                    // "x-guest-token": localStorage.getItem('LOVELIFE_GUEST_TOKEN'),
                    "x-lang-code": Cookies.get('lang') || 'vi'
                }
            });
            await Localization.setup();
            me._initLocalization();
            //
            // await me.getInfo();
            // $('body').removeClass('unload');
            // $('.lsd-container').remove();
            //

            me._initTTag();
            me.emit('ready');
            me.emit('load');
        }

        //

        load();
    }

    _initLocalization() {
        $('.btn-change-language').on('click', this.changeLanguage);
        $('.dropdown-language .dropdown-menu a').on('click', this.changeLanguage);
        //
        $('.dropdown-language').find('a.dropdown-item-foo').each((idx, itemLang) => {
            if ($(itemLang).data('language') === Localization.getLanguage()) {
                $('.language-country-show').empty().html($(itemLang).html());
                $('.language-country-show').find('span').empty();
            }
        });
        $('.language-list > li > a[data-language=' + Localization.getLanguage() + ']').addClass('active');
        //
    }

    changeLanguage(e) {
        let language = $(e.currentTarget).data('language');
        if (language === undefined) {
            language = $(e.target).parents('.btn-change-language').data('language');
        }
        //
        Localization.switchLang(language);
    }

    _eventLayout() {

        this._changeFontSizeStorage();

        // $("#main-search-form").on("submit", function (e) {
        //     e.preventDefault();
        //     let value = $("#main-search-form").find('[name="q"]').val();
        //     window.location.href = `/tim-kiem?q=${value}`;
        // });

        $(document).on('click', '#btn-show-image', function () {
            let arr = [];
            //Ảnh lựa chọn đầu tiên
            let pathChoose = $(this).find('.source').attr('src');
            if (pathChoose.includes("_images")) {
                pathChoose = pathChoose.replace(/72x72/i, 'media');
            }

            let desChoose = $(this).find('.source').attr('alt');
            //MP3
            if (pathChoose.includes("mpga") || pathChoose.includes("mp3")) {
                let html = `<audio class="fancy-box-item" autobuffer="true" controls>
                                    <source src="${pathChoose}" type="audio/mpeg">
                                </audio>`;
                arr.push({
                    src: html,
                    caption: desChoose,
                    type: 'html'
                })
            } else {
                arr.push({
                    src: pathChoose,
                    caption: desChoose,
                })
            }

            var ele = document.querySelector(".media-list").getElementsByTagName("li")
            $.each(ele, function (e, i) {
                //Ảnh video liên quan
                let path = $(i).find('.source').attr('src');
                if (path.includes("_images")) {
                    path = path.replace(/72x72/i, 'media');
                }
                if (pathChoose == path) {
                    return;
                }
                let des = $(i).find('.source').attr('alt');
                //MP3
                if (path.includes("mpga") || path.includes("mp3")) {
                    let html = `<audio class="fancy-box-item" autobuffer="true" controls autoplay>
                                    <source src="${path}" type="audio/mpeg">
                                </audio>`;
                    arr.push({
                        src: html,
                        caption: des,
                        type: 'html'
                    })
                } else {
                    arr.push({
                        src: path,
                        caption: des,
                    })
                }

            })
            Fancybox.show(arr, {})
        })

        $(document).on('click', '.view-more_btn', function () {
            $("#btn-show-image").trigger("click");
        })
    }
    _initMenu() {
        $.get("/api/menu").done(xhr => {
            if (xhr.status == "OK") {
                $('nav.navigation').find(".menu").empty();
                if (xhr.data && xhr.data.length) {
                    $.each(xhr.data, function (idx, item) {
                        if (item.children && item.children.length > 0) {
                            $('#mainMenu').append(Handlebars.compile(MenuWithChildrenTemp)(item));
                        } else {
                            $('#mainMenu').append(Handlebars.compile(MenuTemp)(item));
                        }
                    })
                }
                // class active sub-menu

                let url = location.pathname.toLowerCase() + location.search.toLowerCase();

                let el;

                // class active menu

                $('.navigation ul.menu').find('li.menu-item').removeClass('active');
                $('.navigation ul.menu').find('li.menu-item > a').each(function () {
                    let path = $(this).attr('href');
                    if (path) {
                        // url match condition         
                        path = path.toLowerCase();
                        if (path.length > 1 && url.length > 1 && url === path) {
                            el = $(this).closest('li').addClass('active');
                            return;
                        } else {
                            if (url === '/' && path === '/') {
                                el = $(this).closest('li').addClass('active');
                                return;
                            }

                            if (url == "/dat-cau-hoi" && path == "/hoi-dap") {
                                el = $(this).closest('li').addClass('active');
                                return;
                            }
                        }
                    }
                });

                // class active sub-menu

                $('.navigation ul.sub-menu li').removeClass('active');
                $('.navigation ul.sub-menu li > a').each(function () {

                    let path = $(this).attr('href');
                    if (path) {

                        // url match condition         

                        path = path.toLowerCase();
                        if (path.length > 1 && url.length > 1 && url.substr(1, path.length - 1) == path.substr(1)) {
                            el = $(this).closest('.menu-item').addClass('active');
                            return;

                        }

                    }

                });

                //


            }
        });
    }

    _initFooter() {
        let root = this;

        //SITEFOOTER
        $.get("/api/footer").done(xhr => {
            if (xhr.status == "OK") {
                root.g_$Footer.find(".footer-cnt").html(xhr.data.mo_ta)
            }
        })

        // //LINKS
        // $.get("/api/links").done(xhr => {
        //     if (xhr.status == "OK") {
        //         root.g_$FooterBellow.find("ul").empty();
        //         xhr.data.map(x => {
        //             root.g_$FooterBellow.find("ul").append(`<li><a href="${x.url}">${x.mo_ta}</a></li>`)
        //         });
        //     }
        // })
    }

    _initRightContainer() {
        let root = this;

        $.when($.get("/api/tin-tuc/bai-viet/home"), $.get("/api/poll/randomPollQuestion"), $.get("/api/tin-tuc/album/randomMediaFiles?take=9"))
            .then((newsResponse, pollQuestionResponse, mediaResponse) => {
                let news = newsResponse[0];
                let pollQuestion = pollQuestionResponse[0];
                let medias = mediaResponse[0];
                if (news.status == EnumStatus.OK) {
                    if (news.data) {
                        var rightCategories = news.data.filter(x => x.position === EnumPosition.RIGHT);
                        if (rightCategories.length > 0) {
                            root.g_$RightContent.append(Handlebars.compile(RightCategoryTemplate)(rightCategories))
                        }
                    }
                }
                else {
                    Ultis.error(news.errors ? news.errors[0].message : "Lỗi vui lòng thử lại sau.")
                }
                //
                if (medias.status == EnumStatus.OK) {
                    if (medias.data && medias.data.length) {
                        $.each(medias.data, function (idx, media) {
                            media.url = media.url.replace("media", "72x72");
                        })
                        root.g_$RightContent.append(Handlebars.compile(MediaTemplate)(medias.data))
                    }
                }
                else {
                    Ultis.error(medias.errors ? medias.errors[0].message : "Lỗi vui lòng thử lại sau.")
                }
                //
                if (pollQuestion.status == EnumStatus.OK) {
                    if (pollQuestion.data) {
                        var question = pollQuestion.data;
                        question.allowMultiChoices = question.poll_question_type === EnumQuestionType.MULTIPLECHOICE;
                        root.g_$RightContent.append(Handlebars.compile(PollTemplate)(question))
                    }
                }
                else {
                    Ultis.error(pollQuestion.errors ? pollQuestion.errors[0].message : "Lỗi vui lòng thử lại sau.")
                }
                //

            });

        $(document).on("click", ".btn-vote", (e) => {
            e.preventDefault();
            var $votedForm = $(".voted-form");
            var checkedChoices = $votedForm.find('input[name=voted]:checked');
            var data = [];
            if (checkedChoices.length > 0) {
                $.each(checkedChoices, function (key, element) {
                    data.push(parseInt($(element).attr('value')));
                });
                Utils.showProgress();
                $.ajax({
                    url: '/api/poll/votedChoices',
                    type: "POST",
                    data: { votedChoiceIds: data },
                    success: (xhr) => {
                        if (xhr.status == "OK") {
                            Utils.alert(`Xác nhận bình chọn thành công!`).then(() => {
                                $votedForm.find('input[name=voted]').prop('checked', false);
                            });
                        } else {
                            Utils.error(xhr.errors ? xhr.errors[0].message : "Lỗi vui lòng thử lại sau.")
                        }
                    },
                    error: (e) => {
                        Utils.error('Hệ thống gặp lỗi. Vui lòng thử lại sau.');
                    }
                })
            }
            else {
                Ultis.error("Vui lòng chọn ít nhất 1 lựa chọn.");
            }
        })
    }

    _initMedia() {
        $.get("/api/tin-tuc/album/randomMediaFiles?take=9").done(medias => {
            if (medias.status = "OK") {
                if (medias.data && medias.data.length) {
                    $.each(medias.data, function (idx, media) {
                        media.url = media.url.replace("media", "72x72");
                    })
                    $(".media-cate").html(Handlebars.compile(ListMediaTemplate)(medias.data));
                } else {

                }
            }
        })
    }

    _initTTag() {
        $(".view-more").find('a').html(t`View more`);
        $(".multimedia").html(t`Multimedia`);
        $(".not-found").html(t`Not found`);
        $(".question-ttl").html(t`Question`);
        $(".reply-ttl").html(t`Reply`);
        $(".no").html(t`No`);
        $(".document-name").html(t`Document name`);
        $(".the-issuing-agency").html(t`The issuing agency`);
        $(".abstract").html(t`Abstract`);
        $(".document-type").html(t`Share`);
        $(".share").html(t`Document type`);
        $(".document-group").html(t`Document group`);
        $(".attachments").html(t`Attachments`);
        $(".date-issued").html(t`Date issued`);
        $(".source").html(t`Source`);
        $(".no-information").html(t`No information`);
        $(".multimedia").html(t`Multimedia`);
        $(".traffic-library").html(t`Traffic library`);
        $(".vote").html(t`Vote`);
        $(".br-home").find('a').html(t`Home`);
        $(".br-news").find('a').html(t`News`);
        $(".br-breaking-news").html(t`Breaking news`);
        $(".br-library").find('a').html(t`Library`);
        $(".br-faq").find('a').html(t`FAQ`);
        $(".ask-question").html(t`Ask question`);
        $(".full-name").html(t`Full name`);
        $(".phone-number").html(t`Phone number`);
        $(".address").html(t`Address`);
        $(".title").html(t`Title`);
        $(".description").html(t`Description`);
        $(".send-question").html(t`Send`);
        $(".ask-more-question").html(t`Ask more question?`);
        $(".more-questions").html(t`More questions`);
        $(".recent-accident").html(t`Recent accident`);
        $(".faq").html(t`FAQ`);
        $(".breaking-news").html(t`Breaking news`);
    }

    _changeFontSizeStorage() {

        changeFontSize();

        changeTheme();

        $('#change-font-size .size-lg').on('click', function () {
            changeFontSize("big")
        })

        $('#change-font-size .size-sm').on('click', function () {
            changeFontSize("small")
        })


        function changeFontSize(size) {
            let fontSizeStorage = localStorage.getItem("font-size_storage");
            switch (size) {
                case "big":
                    if (fontSizeStorage == "font-size-small") {
                        localStorage.setItem("font-size_storage", "default");
                    }
                    else {
                        localStorage.setItem("font-size_storage", "font-size-big");
                        $("body").addClass("font-size-big")
                    } break;
                case "small":
                    if (fontSizeStorage == "font-size-big") {
                        localStorage.setItem("font-size_storage", "default");
                    }
                    else {
                        localStorage.setItem("font-size_storage", "font-size-small");
                        $("body").addClass("font-size-small")
                    } break;
                default:
                    if (fontSizeStorage == "font-size-small") {
                        $("body").addClass("font-size-small")
                    } else if (fontSizeStorage == "font-size-big") {
                        $("body").addClass("font-size-big")
                    }
            }
        }

        function changeTheme() {
            let theme = localStorage.getItem("theme-bgcolor");
            switch (theme) {
                case "blue":
                    $("body").addClass("bgcolor-blue")
                    break;
                case "black":
                    $("body").addClass("bgcolor-black")
                    break;
            }
        }

        $('#change-font-size .size-reset').on('click', function () {
            localStorage.removeItem('font-size_storage');
        })
    }
}
