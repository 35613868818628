import { addLocale, useLocale } from 'ttag';
import Cookies from "js-cookie";

import vi from "../i18n/vi.po.json";
import en from "../i18n/en.po.json";

class _Localization {

    ///command: update .po: npx ttag update src/i18n/{lang}.po ./src/pages
    ///command: .po to json: npx ttag po2json src/i18n/{lang}.po --format=compact > src/i18n/{lang}.po.json

    async setup() {
        const locale = Cookies.get('lang') || "vi";
        //
        if (locale === 'vi') {
            addLocale(locale, vi);
            useLocale(locale);
        } else if (locale === 'en') {
            addLocale(locale, en);
            useLocale(locale);
        }
    }

    switchLang(lang) {
        Cookies.set('lang', lang);
        window.location.reload();
    }

    getLanguage() {
        return Cookies.get('lang');
    }
}

const Localization = new _Localization();

export default Localization;
