let EnumLanguage = [
    'vi',
    'en',
    'ko'
];
let EnumStatus = {
    OK: 'OK',
    ERROR: 'ERROR'
}
let EnumResponseStatus = {
    SUCCESS: 'success',
    ERROR: 'error',
    QUESTION: 'question',
    INFO: 'info',
    WARNING: 'warning'
};
let EnumOptionFilter = {
    TINHTHANH: 1,
    VUNGBIEN: 2,
    DOSAUBIEN: 3,
    VUNGDACQUYENKINHTE: 4,
}
let EnumPosition = {
    CENTER: "center",
    RIGHT: "right",
    LEFT: "left",
}

const EnumQuestionType = {
    SINGLECHOICE: 1,
    MULTIPLECHOICE: 2,
};


const EnumDisplayTypeCategory = {
    NORMAL: 'normal',
    SPECIAL: 'special',
};

const EnumWebOption = {
    WebTitle: "Thông tin về tình hình An toàn giao thông trên địa bàn Thành phố Hải phòng",
    WebDescription: "Thông tin về tình hình An toàn giao thông trên địa bàn Thành phố Hải phòng",
}

export {
    EnumLanguage,
    EnumStatus,
    EnumResponseStatus,
    EnumOptionFilter,
    EnumPosition,
    EnumQuestionType,
    EnumWebOption,
    EnumDisplayTypeCategory
};