jQuery.noConflict();
window.matchMedia || (window.matchMedia = function () {
  "use strict";

  // For browsers that support matchMedium api such as IE 9 and webkit
  var styleMedia = (window.styleMedia || window.media);

  // For those that don't support matchMedium
  if (!styleMedia) {
    var style = document.createElement('style'),
      script = document.getElementsByTagName('script')[0],
      info = null;

    style.type = 'text/css';
    style.id = 'matchmediajs-test';

    if (!script) {
      document.head.appendChild(style);
    } else {
      script.parentNode.insertBefore(style, script);
    }

    // 'style.currentStyle' is used by IE <= 8 and 'window.getComputedStyle' for all other browsers
    info = ('getComputedStyle' in window) && window.getComputedStyle(style, null) || style.currentStyle;

    styleMedia = {
      matchMedium: function (media) {
        var text = '@media ' + media + '{ #matchmediajs-test { width: 1px; } }';

        // 'style.styleSheet' is used by IE <= 8 and 'style.textContent' for all other browsers
        if (style.styleSheet) {
          style.styleSheet.cssText = text;
        } else {
          style.textContent = text;
        }

        // Test if media query is true or false
        return info.width === '1px';
      }
    };
  }

  return function (media) {
    return {
      matches: styleMedia.matchMedium(media || 'all'),
      media: media || 'all'
    };
  };
}());
(function () {
  // Bail out for browsers that have addListener support
  if (window.matchMedia && window.matchMedia('all').addListener) {
    return false;
  }

  var localMatchMedia = window.matchMedia,
    hasMediaQueries = localMatchMedia('only all').matches,
    isListening = false,
    timeoutID = 0, // setTimeout for debouncing 'handleChange'
    queries = [], // Contains each 'mql' and associated 'listeners' if 'addListener' is used
    handleChange = function (evt) {
      // Debounce
      clearTimeout(timeoutID);

      timeoutID = setTimeout(function () {
        for (var i = 0, il = queries.length; i < il; i++) {
          var mql = queries[i].mql,
            listeners = queries[i].listeners || [],
            matches = localMatchMedia(mql.media).matches;

          // Update mql.matches value and call listeners
          // Fire listeners only if transitioning to or from matched state
          if (matches !== mql.matches) {
            mql.matches = matches;

            for (var j = 0, jl = listeners.length; j < jl; j++) {
              listeners[j].call(window, mql);
            }
          }
        }
      }, 30);
    };

  window.matchMedia = function (media) {
    var mql = localMatchMedia(media),
      listeners = [],
      index = 0;

    mql.addListener = function (listener) {
      // Changes would not occur to css media type so return now (Affects IE <= 8)
      if (!hasMediaQueries) {
        return;
      }

      // Set up 'resize' listener for browsers that support CSS3 media queries (Not for IE <= 8)
      // There should only ever be 1 resize listener running for performance
      if (!isListening) {
        isListening = true;
        window.addEventListener('resize', handleChange, true);
      }

      // Push object only if it has not been pushed already
      if (index === 0) {
        index = queries.push({
          mql: mql,
          listeners: listeners
        });
      }

      listeners.push(listener);
    };

    mql.removeListener = function (listener) {
      for (var i = 0, il = listeners.length; i < il; i++) {
        if (listeners[i] === listener) {
          listeners.splice(i, 1);
        }
      }
    };

    return mql;
  };
}());
(function ($) {

  $.GSET = {};

  //Breakpoint (MediaQuery) settings
  $.GSET.MODEL = {
    //Breakpoint name (used to move elements, etc.): MediaQuery value
    pc: '(min-width: 971px)',
    tb: 'only screen and (min-width : 641px) and (max-width : 970px)',
    sp: 'only screen and (max-width : 640px)'
  };

  //Element movement settings
  $.GSET.MOVE_ELEM = [{
    elem: '.navigation',
    pc: ['#header', 'after'],
    tb: ['#sma-navi', 'append'],
    sp: ['#sma-navi', 'append']
  }, {
    elem: '.search-pc',
    pc: ['.setting-wrap', 'prepend'],
    tb: ['#sma-navi', 'prepend'],
    sp: ['#sma-navi', 'prepend']
  }];

  //PC / smartphone switching settings
  $.GSET.MODEL_CHANGE_BASE_MODEL = 'pc'; // Breakpoint name on PC display
  $.GSET.MODEL_CHANGE_SP_MODEL = 'sp'; // Breakpoint name on smartphone display

})(jQuery);

//Function definition
(function ($) {
  $.DEVFUNC = {};
  //========================================
  // $.DEVFUNC.customEvent
  //========================================
  $.DEVFUNC.customEvent = function () {
    var listeners = [];

    function add_event(event_name, event_handler) {
      if (listeners[event_name]) {
        listeners[event_name].push(event_handler);
      } else {
        listeners[event_name] = [event_handler];
      }
    }

    function remove_event(event_name, event_handler) {
      if (listeners[event_name]) {
        if (event_handler) {
          while (listeners[event_name].indexOf(event_handler) > -1) {
            listeners[event_name].splice(listeners[event_name].indexOf(event_handler), 1);
          }
        } else {
          listeners[event_name] = [];
        }
      }
    }
    return {
      on: function (event_name, event_handler) {
        if (typeof event_name == 'string') {
          add_event(event_name, event_handler)
        } else if (Array.isArray(event_name)) {
          event_name.forEach(function (name) {
            add_event(name, event_handler);
          });
        }
      },
      off: function (event_name, event_handler) {
        if (typeof event_name == 'undefined') {
          listeners = []
        } else if (typeof event_name == 'string') {
          remove_event(event_name, event_handler)
        } else if (Array.isArray(event_name)) {
          event_name.forEach(function (name) {
            remove_event(name, event_handler);
          });
        }
      },
      trigger: function (event_name, args, _this) {
        if (!_this) _this = null;
        if (listeners[event_name] && listeners[event_name].length) {
          var max = listeners[event_name].length;
          for (var i = 0; i < max; i++) {
            if (args && Array.isArray(args)) {
              listeners[event_name][i].apply(_this, args);
            } else {
              listeners[event_name][i].call(_this, args);
            }
          }
        }
      },
      getEventListeners: function (event_name) {
        if (listeners[event_name]) {
          return listeners[event_name];
        } else {
          return [];
        }
      }
    }
  }
  //========================================
  // $.DEVFUNC.inViewObserver
  //========================================
  $.DEVFUNC.inViewObserver = function (options) { //v1.0
    var defaults = {
      selector: null,
      inviewCondition: function (self_percent, window_percent, inview_px) {
        return self_percent > 0.5
      }
    },
      s = $.extend(defaults, options);

    if (!(s.selector && s.selector.length)) return { active: false }

    var _e = new $.DEVFUNC.customEvent(),
      _t = s.selector,
      ready = false,
      inview_state = false;


    /*---- INIT ----*/
    function init() {
      if (ready) return false;
      $(window).on('scroll resize load', init_event);
      init_event();
      ready = true
    }

    function destroy() {
      if (!ready) return false;
      $(window).off('scroll resize load', init_event);
      _e.off();
      ready = false;
    }
    /*---- PRIVATE FUNCTION ----*/
    function get_inview_self(inview) {
      //console.log(_t.offset().top, window.pageYOffset)
      return _t.outerHeight() ? inview / _t.outerHeight() : 0
    }

    function get_inview_window(inview) {
      if (inview) {
        return {
          top: Math.min(Math.max((_t.offset().top - window.pageYOffset) / $(window).outerHeight(), 0), 1),
          bottom: Math.min(Math.max((_t.offset().top + _t.outerHeight() - window.pageYOffset) / $(window).outerHeight(), 0), 1),
        }
      } else {
        return {
          top: 0,
          bottom: 0
        }
      }
    }

    function get_inview() {
      return Math.max(_t.outerHeight() + Math.min(_t.offset().top - window.pageYOffset, 0) + Math.min((window.pageYOffset + $(window).outerHeight()) - (_t.offset().top + _t.outerHeight()), 0), 0)
    }

    /*---- EVENT ----*/
    function init_event() {
      var inview = get_inview();
      if (inview && s.inviewCondition(get_inview_self(inview), get_inview_window(inview), inview)) {
        if (!inview_state) {
          inview_state = true;
          _e.trigger('in_view', inview);
        }
        _e.trigger('viewing');
      } else {
        if (inview_state) {
          inview_state = false;
          _e.trigger('out_of_view');
        }
        _e.trigger('not_viewing');
      }
    }


    /*---- PUBLIC ----*/
    return {
      //public function and variables
      active: true,
      init: init,
      checkCondition: init_event,
      destroy: destroy,
      on: _e.on,
      off: _e.off,
      trigger: _e.trigger,
      selector: s.selector
    }
  }
  //========================================
  // ▼switchSlickLayout
  //========================================
  $.DEVFUNC.updateSlick = function (options) {
    var defaults = {
      wrap: $('#slick-cover .slick-inner'),
      selector: $('#slick-cover .slick-slides'),
      slickPcSettings: {
        dots: true,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        focusOnSelect: true,
        autoplay: true,
        arrows: true,
      },
      prevArrowHover: null,
      nextArrowHover: null,
      slickTbSettings: false,
      slickSpSettings: false,
      useStopControl: true,
      stopButtonText: 'Dừng lại',
      startButtonText: 'Bắt đầu',
      pcCallBack: function () { },
      spCallBack: function () { },
      tbCallBack: function () { },
    },
      s = $.extend(defaults, options),
      first_pc = true,
      first_tb = true,
      first_sp = true,
      first_pc_start = true,
      first_tb_start = true,
      first_sp_start = true,
      tb_selector,
      sp_selector;

    /*---- INIT ----*/
    if (s.selector.length && $.fn.slick) {
      sp_selector = $(s.selector[0].outerHTML);
      tb_selector = $(s.selector[0].outerHTML);
    } else {
      return {
        active: false
      }
    }

    /*---- PRIVATE FUNCTION ----*/
    function pc_layout() {
      sp_selector.detach();
      tb_selector.detach();
      s.wrap.append(s.selector);
      if (s.slickPcSettings) {
        if (first_pc) {
          first_pc = false;
          s.selector.slick(s.slickPcSettings);
          append_control(s.selector);
          hoverArrow();
          if (first_pc_start == false) {
            s.selector.slick('slickPause');
            s.selector.find('.btn_slides').removeClass('stop').addClass('start').find('span').text(s.startButtonText);
          }
        }
        s.selector.slick('setPosition');
        s.pcCallBack();
      }
    }

    function tb_layout() {
      s.selector.detach();
      sp_selector.detach();
      s.wrap.append(tb_selector);
      hoverArrow();
      if (s.slickTbSettings) {
        if (first_tb) {
          first_tb = false;
          tb_selector.slick(s.slickTbSettings);
          append_control(tb_selector);
          if (first_tb_start == false) {
            tb_selector.slick('slickPause');
            tb_selector.find('.btn_slides').removeClass('stop').addClass('start').find('span').text(s.startButtonText);
          }
        }
        tb_selector.slick('setPosition');
        s.tbCallBack();
      }
    }

    function sp_layout() {
      s.selector.detach();
      tb_selector.detach();
      s.wrap.append(sp_selector);
      hoverArrow();
      if (s.slickSpSettings) {
        if (first_sp) {
          first_sp = false;
          sp_selector.slick(s.slickSpSettings);
          append_control(sp_selector);
          if (first_sp_start == false) {
            sp_selector.slick('slickPause');
            sp_selector.find('.btn_slides').removeClass('stop').addClass('start').find('span').text(s.startButtonText);
          }
        }
        sp_selector.slick('setPosition');
        s.spCallBack();
      }
    }

    function append_control(target) {
      if (s.useStopControl) {
        target.find('.slick-dots').wrap('<div class="slick_control"></div>');
        target.find('.slick_control').prepend('<p class="btn_slides stop"><a href="javascript:void(0);"><span>Dừng lại</span></a></p>');
        target.find('.slick_control .btn_slides').on('click', function (e) {
          if ($(this).hasClass('stop')) {
            if (s.slickPcSettings && !first_pc) {
              s.selector.slick('slickPause');
              s.selector.find('.btn_slides').removeClass('stop').addClass('start').find('span').text(s.startButtonText);
            }
            if (s.slickTbSettings && !first_tb) {
              tb_selector.slick('slickPause');
              tb_selector.find('.btn_slides').removeClass('stop').addClass('start').find('span').text(s.startButtonText);
            }
            if (s.slickSpSettings && !first_sp) {
              sp_selector.slick('slickPause');
              sp_selector.find('.btn_slides').removeClass('stop').addClass('start').find('span').text(s.startButtonText);
            }
            first_pc_start = false;
            first_tb_start = false;
            first_sp_start = false;
          } else {
            if (s.slickPcSettings && !first_pc) {
              s.selector.slick('slickPlay');
              s.selector.find('.btn_slides').removeClass('start').addClass('stop').find('span').text(s.stopButtonText);
            }
            if (s.slickTbSettings && !first_tb) {
              tb_selector.slick('slickPlay');
              tb_selector.find('.btn_slides').removeClass('start').addClass('stop').find('span').text(s.stopButtonText);
            }
            if (s.slickSpSettings && !first_sp) {
              sp_selector.slick('slickPlay');
              sp_selector.find('.btn_slides').removeClass('start').addClass('stop').find('span').text(s.stopButtonText);
            }
            first_pc_start = true;
            first_tb_start = true;
            first_sp_start = true;
          }
        });
      }
    }

    function hoverArrow() {
      if (s.nextArrowHover && s.prevArrowHover) {
        var slickPrev = $('.service_slide_gallery').find('.slick-prev.slick-arrow').children('img').attr('src');
        var slickNext = $('.service_slide_gallery').find('.slick-next.slick-arrow').children('img').attr('src');
        $('.service_slide_gallery').find('.slick-arrow').off('mouseover mouseleave');
        $('.service_slide_gallery').find('.slick-prev.slick-arrow').on('mouseover', 'img', function () {
          $(this).attr('src', s.prevArrowHover);
        })
        $('.service_slide_gallery').find('.slick-prev.slick-arrow').on('mouseleave', 'img', function () {
          $(this).attr('src', slickPrev);
        })
        $('.service_slide_gallery').find('.slick-next.slick-arrow').on('mouseover', 'img', function () {
          $(this).attr('src', s.nextArrowHover);
        })
        $('.service_slide_gallery').find('.slick-next.slick-arrow').on('mouseleave', 'img', function () {
          $(this).attr('src', slickNext);
        })
      }
    }

    /*---- PUBLIC ----*/
    return {
      active: true,
      resize: function (e) {
        if (e == 'pc') {
          pc_layout();
        } else if (e == 'tb') {
          tb_layout();
        } else {
          sp_layout();
        }
      }
    }
  };
  //========================================
  // ▼ Sticky floating
  //========================================
  $.DEVFUNC.StickyFloating = function (options) { //v1.0
    var defaults = {
      //Default Options
      target: $('.slidebar-link'),
      area_floating: $('.menu-floating'),
      area_wrap_pc: $('.slidebar .slidebar-content .nav-slidebar'),
      area_wrap_sp: $('.menu-floating .floating-content'),
      button: $('.floating-button'),
      breakpoint: 640,
      scrolload: false
    },
      s = $.extend(defaults, options);
    if (!(s.target && s.target.length && s.area_wrap_pc && s.area_wrap_pc.length && s.area_wrap_sp && s.area_wrap_sp.length)) return { active: false }

    /*---- INIT ----*/
    function init(e) {
      if ($(window).width() <= s.breakpoint && e == 'sp') {
        s.area_wrap_sp.append(s.target);
      } else {
        s.area_wrap_pc.append(s.target);
      }
    }
    /*---- EVENT ----*/
    s.button.on('click', function (e) {
      e.preventDefault();
      if (!s.area_floating.hasClass('side-close')) {
        s.area_floating.addClass('side-close');
        $(this).children('.menu-toggler__dots').addClass('is-active');
      } else {
        s.area_floating.removeClass('side-close');
        $(this).children('.menu-toggler__dots').removeClass('is-active');
      }
    });
    /*---- END EVENT ----*/

    /*---- PRIVATE FUNCTION ----*/

    /*---- END PRIVATE FUNCTION ----*/

    /*---- PUBLIC ----*/
    return {
      //public function and variables
      active: true,
      init: init
    }
  }
  //========================================
  // ▼ Accordion
  //========================================
  $.DEVFUNC.accordion = function (options) {
    var defaults = {
      wrap: $('.accordion'),
      item: $('.accordion-item'),
      target: '.accordion-title',
      contents: $('.accordion-content'),
      contentStr: '.accordion-content',
      hasClassSub: 'accordion-content'
    },
      s = $.extend(defaults, options);
    //Private Function
    function toggleSlide() {
      s.wrap.each(function () {
        $(this).children('.active').children(s.contentStr).slideDown(450);
        $(this).children('.active').addClass('active');
      });
      s.wrap.off('click.accordionMenu');
      s.wrap.on('click.accordionMenu', s.target, function (e) {
        if ($(this).next().hasClass(s.hasClassSub) == false) {
          return;
        }
        var parent = $(this).parent().parent();
        var subAccordion = $(this).next();
        parent.children('.active').children(s.contentStr).slideUp(450);
        parent.children('.active').removeClass('active');

        if (subAccordion.is(":visible")) {
          $(this).parent().removeClass("active");
          subAccordion.slideUp(450);
        } else {
          $(this).parent().addClass("active");
          subAccordion.slideDown(450);
        }

        e.preventDefault();
      });
    }
    //Public Fuction
    return {
      handleAccordion: function () {
        toggleSlide();
      }
    }
  }
  //========================================
  // ▼ Sticky floating
  //========================================
  $.DEVFUNC.StickyFloating = function (options) { //v1.0
    var defaults = {
      //Default Options
      target: $('.slidebar-link'),
      area_floating: $('.menu-floating'),
      area_wrap_pc: $('.slidebar .slidebar-content .nav-slidebar'),
      area_wrap_sp: $('.menu-floating .floating-content'),
      button: $('.floating-button'),
      breakpoint: 640,
      scrolload: false
    },
      s = $.extend(defaults, options);
    if (!(s.target && s.target.length && s.area_wrap_pc && s.area_wrap_pc.length && s.area_wrap_sp && s.area_wrap_sp.length)) return { active: false }

    /*---- INIT ----*/
    function init(e) {
      if ($(window).width() <= s.breakpoint && e == 'sp') {
        s.area_wrap_sp.append(s.target);
      } else {
        s.area_wrap_pc.append(s.target);
      }
    }
    /*---- EVENT ----*/
    s.button.on('click', function (e) {
      e.preventDefault();
      if (!s.area_floating.hasClass('side-close')) {
        s.area_floating.addClass('side-close');
        $(this).children('.menu-toggler__dots').addClass('is-active');
      } else {
        s.area_floating.removeClass('side-close');
        $(this).children('.menu-toggler__dots').removeClass('is-active');
      }
    });
    /*---- END EVENT ----*/

    /*---- PRIVATE FUNCTION ----*/

    /*---- END PRIVATE FUNCTION ----*/

    /*---- PUBLIC ----*/
    return {
      //public function and variables
      active: true,
      init: init
    }
  }
  //========================================
  // Back To Top
  //========================================
  $.DEVFUNC.BackToTop = function (options) { //v1.0
    var defaults = {
      //Default Options
      selector: $('.back-top'),
      switchClass: 'back-top-fixed',
      top: 100,
      breakpoint: 640,
      offset_plus_pc: 70,
      offset_plus_sp: 0
    },
      s = $.extend(defaults, options);
    if (!(s.selector && s.selector.length)) return { active: false }

    /*---- INIT ----*/
    function init(e) {
      function scrollWindow(e) {
        $(window).on('scroll load', function () {
          var offset = $('#footer').offset().top
          var offset_math = (e == 'pc') ? Math.floor(offset) + s.offset_plus_pc : Math.floor(offset) + s.offset_plus_sp;
          var scroll_position = ($(window).width() < $('.container').width() && $(window).width() > s.breakpoint) ? (($('.container').width() / $(window).width()) * $(window).height() + $(window).scrollTop()) : $(window).scrollTop() + $(window).height();
          if (scroll_position <= offset_math) {
            s.selector.addClass(s.switchClass);
          }
          else {
            s.selector.removeClass(s.switchClass);
          }
        });
      }
      if (device != 'sp') {
        scrollWindow(e)
      } else {
        setTimeout(function () { scrollWindow(e) }, 100);
      }
      $(s.selector).on('click', function (e) {
        e.preventDefault();
        $("html, body").animate({ scrollTop: 0 }, 250);
      });
      $(window).on('scroll load', function () {
        if ($(window).scrollTop() > s.top) {
          s.selector.fadeIn(150);
        }
        else {
          s.selector.fadeOut(150);
        }
      })
    }
    /*---- PUBLIC ----*/
    return {
      //public function and variables
      active: true,
      init: init
    }
  }
  //========================================
  //▼ Smartphone menu
  //========================================
  $.DEVFUNC.mobileMenu = function (options) {
    var o = $.extend({
      menuBtn: [{
        oBtn: '#tmp_hnavi_lmenu a',
        target: '#tmp_sma_lmenu',
        customClass: '',
        parentWrap: '',
        setOverflow: false,
        clickOverlay: false
      }],
      /** Close button */
      closeBtn: '.close_btn',
      /** Class to be given to body (empty if unnecessary) */
      addClass: 'spmenu_open',
      callback: function () { },
      /** Assign active status to Button menu */
      activeClass: 'active'
    }, options);
    var dataClass = [];
    var l = o.menuBtn.length;
    if (l >= 0) {
      for (var i = 0; i < l; i++) {
        dataClass.push(o.menuBtn[i]['customClass']);
      }
    }
    if (l >= 0) {
      for (var i = 0; i < l; i++) {
        $(o.menuBtn[i].oBtn).on('click', {
          elem: o.menuBtn[i].target,
          changeText: (o.menuBtn[i].hasOwnProperty('changeText')) ? o.menuBtn[i].changeText : false,
          slideEffect: (o.menuBtn[i].hasOwnProperty('slideEffect')) ? o.menuBtn[i].slideEffect : false,
          oTxt: (o.menuBtn[i].hasOwnProperty('oTxt')) ? o.menuBtn[i].oTxt : 'メニュー',
          cTxt: (o.menuBtn[i].hasOwnProperty('cTxt')) ? o.menuBtn[i].cTxt : '閉じる',
          customClass: (o.menuBtn[i].hasOwnProperty('customClass')) ? o.menuBtn[i].customClass : false,
          parentWrap: (o.menuBtn[i].hasOwnProperty('parentWrap')) ? o.menuBtn[i].parentWrap : false,
          clickOverlay: (o.menuBtn[i].hasOwnProperty('clickOverlay')) ? o.menuBtn[i].clickOverlay : false,
          setOverflow: (o.menuBtn[i].hasOwnProperty('setOverflow')) ? o.menuBtn[i].setOverflow : false,
          noPosition: (o.menuBtn[i].hasOwnProperty('noPosition')) ? o.menuBtn[i].noPosition : false,
          beforeOpenCallback: (o.menuBtn[i].hasOwnProperty('beforeOpenCallback')) ? o.menuBtn[i].beforeOpenCallback : false,
          openCallback: (o.menuBtn[i].hasOwnProperty('openCallback')) ? o.menuBtn[i].openCallback : false,
          beforeCloseCallback: (o.menuBtn[i].hasOwnProperty('beforeCloseCallback')) ? o.menuBtn[i].beforeCloseCallback : false,
          closeCallback: (o.menuBtn[i].hasOwnProperty('closeCallback')) ? o.menuBtn[i].closeCallback : false,
        }, function (e) {
          if (dataClass.length) {
            for (j = 0; j < dataClass.length; j++) {
              $('#wrapper').removeClass(dataClass[j]);
            }
          }
          e.preventDefault ? e.preventDefault() : (e.returnValue = false);
          var self = $(this);
          if (self.hasClass(o.activeClass)) {
            self.removeClass(o.activeClass);
            $(e.data.elem).removeClass(o.activeClass);
            if (e.data.changeText) replaceText(self[0], e.data.cTxt, e.data.oTxt);
            if (e.data.beforeCloseCallback) e.data.beforeCloseCallback()
            if (e.data.slideEffect) {
              $(e.data.elem).stop().slideUp(300, e.data.closeCallback ? e.data.closeCallback : undefined);
            } else {
              $(e.data.elem).hide();
              if (e.data.closeCallback) setTimeout(e.data.closeCallback, 0)
            }
            $('#wrapper').removeClass(o.addClass);
            $('#wrapper').removeClass(e.data.customClass);
            if (e.data.setOverflow) {
              $('html, body').removeAttr('style');
            }
          } else {
            for (var i = 0; i < o.menuBtn.length; i++) {
              if ($(o.menuBtn[i].oBtn).hasClass(o.activeClass)) {
                $(o.menuBtn[i].oBtn).removeClass(o.activeClass);
                $(o.menuBtn[i].target).hide();
                if (o.menuBtn[i].beforeCloseCallback) o.menuBtn[i].beforeCloseCallback()
                var text = {
                  oTxt: (o.menuBtn[i].hasOwnProperty('oTxt')) ? o.menuBtn[i].oTxt : 'メニュー',
                  cTxt: (o.menuBtn[i].hasOwnProperty('cTxt')) ? o.menuBtn[i].cTxt : '閉じる',
                  changeText: (o.menuBtn[i].hasOwnProperty('changeText')) ? o.menuBtn[i].changeText : false,
                  customClass: (o.menuBtn[i].hasOwnProperty('customClass')) ? o.menuBtn[i].customClass : false,
                  addBodyClass: (o.menuBtn[i].hasOwnProperty('addBodyClass')) ? o.menuBtn[i].addBodyClass : false,
                }
                if (text.changeText) {
                  replaceText($(o.menuBtn[i].oBtn)[0], text.cTxt, text.oTxt)
                }
              }
              if (e.data.slideEffect) $(o.menuBtn[i].target).stop().slideUp(300); else $(e.data.elem).hide();
              // Off click overlay
              $('#wrapper').off('click.spMenu');
            }
            self.addClass(o.activeClass);
            $(e.data.elem).addClass(o.activeClass);
            if (e.data.changeText) replaceText(self[0], e.data.oTxt, e.data.cTxt);
            if (e.data.beforeOpenCallback) e.data.beforeOpenCallback()
            if (e.data.slideEffect) {
              $(e.data.elem).stop().slideDown(350, e.data.openCallback ? e.data.openCallback : undefined);
            } else {
              $(e.data.elem).show();
              if (e.data.openCallback) setTimeout(e.data.openCallback, 0)
            }
            if (o.addClass) $('#wrapper').addClass(o.addClass);
            if (e.data.customClass) $('#wrapper').addClass(e.data.customClass);
            if (e.data.setOverflow && self.hasClass(o.activeClass)) {
              $('html, body').css({ 'overflow': 'hidden' });
            } else if (!e.data.noPosition) {
              $('html, body').removeAttr('style');
            } else {
              $('html, body').removeAttr('style');
            }
            $('#wrapper').on('click.spMenu', {
              parentWrap: e.data.parentWrap,
              clickOverlay: e.data.clickOverlay,
            }, clickOutside);
          }
          function clickOutside(_e) {
            var target = $(_e.target);
            if ($('#wrapper').hasClass('spmenu_open')) {
              if (_e.data.clickOverlay && self.has(target).length == 0 && !target.is(self) && target.parents(_e.data.parentWrap).length == 0 && !target.is(_e.data.parentWrap)) {
                $(o.closeBtn).trigger('click');
              }
            }
          }
          o.callback();
          return false;
        });
        $(o.menuBtn[i].target).on('click', o.closeBtn, {
          elem: o.menuBtn[i],
          changeText: (o.menuBtn[i].hasOwnProperty('changeText')) ? o.menuBtn[i].changeText : false,
          slideEffect: (o.menuBtn[i].hasOwnProperty('slideEffect')) ? o.menuBtn[i].slideEffect : false,
          oTxt: (o.menuBtn[i].hasOwnProperty('oTxt')) ? o.menuBtn[i].oTxt : 'メニュー',
          cTxt: (o.menuBtn[i].hasOwnProperty('cTxt')) ? o.menuBtn[i].cTxt : '閉じる',
          customClass: (o.menuBtn[i].hasOwnProperty('customClass')) ? o.menuBtn[i].customClass : false,
          parentWrap: (o.menuBtn[i].hasOwnProperty('parentWrap')) ? o.menuBtn[i].parentWrap : false,
          clickOverlay: (o.menuBtn[i].hasOwnProperty('clickOverlay')) ? o.menuBtn[i].clickOverlay : false,
          setOverflow: (o.menuBtn[i].hasOwnProperty('setOverflow')) ? o.menuBtn[i].setOverflow : false,
          beforeCloseCallback: (o.menuBtn[i].hasOwnProperty('beforeCloseCallback')) ? o.menuBtn[i].beforeCloseCallback : false,
          closeCallback: (o.menuBtn[i].hasOwnProperty('closeCallback')) ? o.menuBtn[i].closeCallback : false,
        }, function (e) {
          e.preventDefault ? e.preventDefault() : (e.returnValue = false);
          $(e.data.elem.oBtn).removeClass(o.activeClass);
          $(e.data.elem.target).removeClass(o.activeClass);
          if (e.data.changeText) replaceText($(e.data.elem.oBtn)[0], e.data.cTxt, e.data.oTxt);
          if (e.data.beforeCloseCallback) e.data.beforeCloseCallback()
          if (e.data.slideEffect) {
            $(e.data.elem.target).stop().slideUp(300, e.data.closeCallback ? e.data.closeCallback : undefined);
          } else {
            $(e.data.elem).hide();
            if (e.data.closeCallback) setTimeout(e.data.closeCallback, 0)
          }
          $('#wrapper').removeClass(o.addClass);
          $('#wrapper').removeClass(e.data.customClass);
          if (e.data.setOverflow) {
            $('html, body').removeAttr('style');
          }
          o.callback();
          return false;
        });
      }
    }

    $(document).on('keydown', function (e) {
      if (e.which == 27) {
        $(o.closeBtn).trigger('click');
      }
    });

    function replaceText(selector, oldtext, newtext) {
      if (selector) {
        for (var i = 0; i < selector.childNodes.length; i++) {
          if (selector.childNodes[i].nodeType == 3) {
            if (selector.childNodes[i].textContent.trim() == oldtext) selector.childNodes[i].textContent = newtext;
          } else {
            if (selector.childNodes[i].childNodes.length) selector.childNodes[i].innerHTML = newtext;
          }
        }
      }
    }
  }
  //========================================
  //▼ Move element
  //========================================
  $.DEVFUNC.elemMove = function (option, device) {
    var option = $.GSET.MOVE_ELEM;
    if (!option || option.length <= 0) return false; //要素移動の設定が無い、もしくは移動の要素が無い場合に中断
    var eLength = option.length;
    for (i = 0; i < eLength; i++) {
      if (typeof option[i].flg === "undefined" || option[i].flg || option[i][device] || $(option[i].elem).length) {
        switch (option[i][device][1]) {
          case ("append"):
            $(option[i][device][0]).append($(option[i].elem));
            break;
          case ("prepend"):
            $(option[i][device][0]).prepend($(option[i].elem));
            break;
          case ("after"):
            $(option[i][device][0]).after($(option[i].elem));
            break;
          case ("before"):
            $(option[i][device][0]).before($(option[i].elem));
            break;
        }
      }
    }
  };
  //========================================
  //▼ MatchMedia
  //========================================
  var mql = Array();
  $.DEVFUNC.MATCHMEDIA = function () {
    for (device in $.GSET.MODEL) {
      var mediaQuery = matchMedia($.GSET.MODEL[device]);
      var mc = localStorage.getItem('pc');

      // Run when the page loads
      handle(mediaQuery);

      // Now runs even if the window size is changed
      mediaQuery.addListener(handle);

      function handle(mq) {
        if (!mc) {
          for (device in $.GSET.MODEL) {
            if (mql[device].matches && !$('body').hasClass('device_' + device)) {
              $('body').addClass('device_' + device);
              $.HANDLEBREAKPOINT(device);
            }
            if (!mql[device].matches && $('body').hasClass('device_' + device)) {
              $('body').removeClass('device_' + device);
            }
          }
        } else if (mc) {
          for (device in $.GSET.MODEL) {
            $('body').removeClass('device_' + device);
          }
          device = 'pc';
          $('body').addClass('device_' + $.GSET.MODEL_CHANGE_SP_MODEL);
          $.HANDLEBREAKPOINT($.GSET.MODEL_CHANGE_BASE_MODEL);
        }
      }
    }
  };
  for (device in $.GSET.MODEL) {
    var mc = localStorage.getItem('pc');
    if (mc) {
      mql[device] = 'pc';
    } else {
      mql[device] = matchMedia($.GSET.MODEL[device]);
    }
  }

  //========================================
  //updateTheme
  //========================================
  $.DEVFUNC.updateTheme = function (options) {
    var c = $.extend({
      switchArea: 'accessibility',
      switchClass: 'update-theme',
      defaultLinkName: 'default',
    }, options);
    $('.' + c.switchClass).on('click.update-theme', function () {
      var sName = $(this).closest('.' + c.switchArea).attr('id').replace('change-', 'theme-');
      var value = $(this).attr('name');
      if (sName) {
        if (value == c.defaultLinkName) {
          changeUI(sName);
        } else {
          changeUI(sName, value);
        }
      }
      return false;
    });

    function changeUI(sName, value) {
      var classList = $('body').attr('class').split(' ');
      var cListLength = classList.length;
      var regexp = new RegExp(sName.replace('theme-', '') + '(.*?)', 'g');
      if (cListLength > 0) {
        for (var i = 0; i < cListLength; i++) {
          var m = classList[i].match(regexp);
          if (m) $('body').removeClass(classList[i]);
        }
      }
      if (value) {
        localStorage.setItem(sName, value);
        $('body').addClass(sName.replace('theme-', '') + '-' + value);
      } else {
        localStorage.removeItem(sName);
      }
    }
  };
  //========================================
  //updateThemeTop
  //========================================
  $.DEVFUNC.updateThemeTop = function (options) {
    var c = $.extend({
      switchArea: 'accessibility-list',
      switchClass: 'style-theme',
      defaultLinkName: 'default',
    }, options);
    $('.' + c.switchClass).on('click.style-theme', function () {
      var sName = $(this).closest('.' + c.switchArea).attr('id').replace('style-', 'theme-');
      var value = $(this).attr('name');
      if (sName) {
        if (value == c.defaultLinkName) {
          changeUI(sName);
        } else {
          changeUI(sName, value);
        }
      }
      return false;
    });

    function changeUI(sName, value) {
      var classList = $('body').attr('class').split(' ');
      var cListLength = classList.length;
      var regexp = new RegExp(sName.replace('theme-', '') + '(.*?)', 'g');
      if (cListLength > 0) {
        for (var i = 0; i < cListLength; i++) {
          var m = classList[i].match(regexp);
          if (m) $('body').removeClass(classList[i]);
        }
      }
      if (value) {
        localStorage.setItem(sName, value);
        $('body').addClass(sName.replace('theme-', '') + '-' + value);
      } else {
        localStorage.removeItem(sName);
      }
    }
  };
  //========================================
  //▼changeStyle
  //========================================
  $.DEVFUNC.changeBackground = function (options) { //v1.0
    var defaults = {
      area: $('#ele-wrap'),
      selector: $('#ele-wrap .img-item'),
    },
      s = $.extend(defaults, options)
      //Private Variables
      ;
    if (!s.area.length || !s.selector.length) return false;
    s.selector.each(function () {
      var attrSrc = $(this).find('img').attr('src');
      $(this).css({
        background: 'url(' + attrSrc + ') no-repeat center center',
        'background-size': 'cover',
      })
      $(this).animate({
        opacity: 1
      }, {
        duration: 300,
      });
      $(this).find('img').css('display', 'none');
    });
  }
})(jQuery);