import _Layout from "../_layout";
import Utils from "../../helpers/utils.js";
import { EnumStatus, EnumPosition, EnumDisplayTypeCategory } from "../../helpers/enum.js";
import moment from "moment";
import Handlebars from "handlebars";
import CenterCategoryTemplate from "./templates/center_category_temp.hbs";
import CenterBannerTemplate from "./templates/center_banner_temp.hbs";
import LastestNewsTemplate from "././templates/lastest_news_temp.hbs";
import DocumentTableTemplate from "./templates/doc_table_temp.hbs";

import RightContentContainer from "../../components/right_content_container.js";

import './index.scss'
export default class Index extends _Layout {
    constructor() {
        super('top');
        this.on('ready', () => {
            this.g_$CenterContentElement = $(".panel-article");
            //
            this._initLayout();
            this.g_$RightContent = $(".col-right-inner");
            new RightContentContainer(this.g_$RightContent, {
                hideHighlightNews: true
            });
            //this._initTTag();
        })
        
    }


    _initLayout() {
        let root = this;


        $.when($.get("/api/tin-tuc/bai-viet/noi-bat?pageLength=7"),
            $.get("/api/tin-tuc/bai-viet/home"),
            $.get("/api/van-ban/"),
            $.get("/api/banner/items"))
            .then((latestNewsResponse, newsResponse, docsResponse, bannerResponse) => {
                let latestNews = latestNewsResponse[0];
                let news = newsResponse[0];
                let docs = docsResponse[0];
                let banner = bannerResponse[0];
                let centerBanners = [];

                if (banner.status == EnumStatus.OK && banner.data.length) {
                    //banner.data.map((v, i) => {
                    //    v.avatar = Utils.replaceSizeMedia(v.avatar, "838x105");
                    //})
                    centerBanners = banner.data.filter(x => x.position == EnumPosition.CENTER);
                }
                else {
                    console.log(banner.errors ? banner.errors[0].message : "Lỗi vui lòng thử lại sau.")
                }

                if (latestNews.status == EnumStatus.OK) {
                    if (latestNews.data) {
                        latestNews.data.map((v, i) => {
                            if (i == 0) {
                                v.anh_dai_dien = Utils.replaceSizeMedia(v.anh_dai_dien, "543x288");
                            } else {
                                v.anh_dai_dien = Utils.replaceSizeMedia(v.anh_dai_dien, "116x80");
                            }
                        })
                        root.g_$CenterContentElement.append(Handlebars.compile(LastestNewsTemplate)(latestNews.data))
                    }
                }
                else {
                    Utils.error(latestNews.errors ? latestNews.errors[0].message : "Lỗi vui lòng thử lại sau.")
                }

                if (centerBanners.length > 0) {
                    root.g_$CenterContentElement.append(Handlebars.compile(CenterBannerTemplate)(centerBanners[0]));
                    centerBanners = centerBanners.slice(1);
                }

                if (news.status == EnumStatus.OK) {
                    if (news.data) {
                        news.data.map((v, i) => {
                            v.listBaiViet.map((vv, ii) => {
                                if (ii == 0) {
                                    vv.anh_dai_dien = Utils.replaceSizeMedia(vv.anh_dai_dien, "388x268");
                                } else {
                                    vv.anh_dai_dien = Utils.replaceSizeMedia(vv.anh_dai_dien, "192x128");
                                }
                            })
                        })
                        var centerCategories = news.data.filter(x => x.position === EnumPosition.CENTER);
                        if (centerCategories.length > 0) {
                            if (centerBanners.length > 0) {
                                let filterBanners = centerBanners.slice(0, 2);
                                if (centerCategories.length > 2) {
                                    centerCategories[centerCategories.length - 2].banner = filterBanners;
                                }
                                centerBanners = centerBanners.slice(2);
                            }
                            root.g_$CenterContentElement.append(Handlebars.compile(CenterCategoryTemplate)(centerCategories))
                        }
                    }
                }
                else {
                    Utils.error(news.errors ? news.errors[0].message : "Lỗi vui lòng thử lại sau.")
                }


                if (centerBanners.length) {
                    centerBanners.forEach(item => {
                        item.avatar = Utils.replaceSizeMedia(item.avatar, "1038x105");
                        root.g_$CenterContentElement.append(Handlebars.compile(CenterBannerTemplate)(item))
                    })
                }

                if (docs.status == EnumStatus.OK) {
                    if (docs.data) {
                        var data = docs.data.slice(0, 5);
                        if (data.length > 0) {
                            root.g_$CenterContentElement.append(Handlebars.compile(DocumentTableTemplate)(data))
                        }
                    }
                }
                else {
                    Utils.error(docs.errors ? docs.errors[0].message : "Lỗi vui lòng thử lại sau.")
                }
            });
    }
}

new Index();