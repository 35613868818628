(function($) {
  /*****No javascript*****/
  $('body').removeClass('no-js').addClass('is-js');
  //========================================
  //▼ Select value Language
  //========================================
  // var select_language = function() {
  //   $('.dropdown-language .dropdown-menu a').click(function() {
  //     var selText = $(this).find('img').attr('src');
  //     console.log(selText);
  //     $('.dropdown-language .language-text img').attr('src', selText);
  //   });
  // }
  // select_language();
  
  //========================================
  //▼ Smartphone menu settings
  //========================================
  var backUpScroll = 0;
	$.DEVFUNC.mobileMenu({
		menuBtn: [{
			slideEffect: true,
			oBtn:'#btn-nav-sp a',
			target:'#smartphone-menu',
			changeText: true,
			customClass: 'rmenu',
			oTxt: '',
			cTxt: '',
			setOverflow: false,
			parentWrap: '#tmp_sma_rmenu',
			clickOverlay: true
		}],
		closeBtn: '.menu-sp-close a',
		addClass: 'menu-sp-open',
		activeClass : 'active',
		beforeCallback: function(){
			if (!$('.spmenu_open').length){
				backUpScroll = window.pageYOffset
			}
		},
		callback: function(){
			if ($('.spmenu_open').length){
				window.scrollTo(0,0)
			}else{
				window.scrollTo(0,backUpScroll)
			}
      if($('.dropdown-language').hasClass('show')){
        $('.dropdown-language .btn-language-country').trigger('click');
      }
      if(!$('.menu-floating').hasClass('side-close')){
        $('.menu-floating .floating-button').trigger('click');
      }
		}
	});
  //========================================
  //▼ Back To Top
  //========================================
  var back_top = new $.DEVFUNC.BackToTop({
    selector: $('.back-top'),
    switchClass: 'back-top-fixed',
    top: 150,
    breakpoint: 640,
    offset_plus_pc: 30,
    offset_plus_sp: -10
  });
  //================================================
  //▼ StickyFloating For Map page
  //================================================
  var sticky_map = new $.DEVFUNC.StickyFloating({
    target: $('.map-aside'),
    area_floating: $('.menu-floating'),
    area_wrap_pc: $('.bussiness-map-inner'),
    area_wrap_sp: $('.menu-floating .floating-content'),
    button: $('.floating-button'),
    breakpoint: 640
  });
  //========================================
  //▼ Processing for each breakpoint
  //========================================
  // Processing for each breakpoint
  $.HANDLEBREAKPOINT = function(device) {
    $.DEVFUNC.elemMove($.GSET.MOVE_ELEM, device); //Move element
    // Back To Top
    if (back_top.active) back_top.init(device);
    // Sticky Map Page
    if (sticky_map.active) sticky_map.init(device);
  }
  $.DEVFUNC.MATCHMEDIA();
  //=========================================================
	//▼ Navigation superfish
	//=========================================================
	var navigation;
	if($('.navigation .menu').length){
		// initialise plugin
		navigation = $('.navigation .menu').superfish();
	}
  //========================================
  //▼ Change color
  //========================================
  $.DEVFUNC.updateThemeTop();
  $.DEVFUNC.updateTheme();
  //========================================
  //▼ matchMedia superfish
  //========================================
  var mql = window.matchMedia('(max-width: 1350px)');

  function screenSwitchDevice(e) {
    if (e.matches) {
      /* The viewport is less than, or equal to, 960px pixels wide */
      navigation.superfish('destroy');
      //========================================
      //▼ Accordion Menu Smartphone
      //========================================
      var accordion = new $.DEVFUNC.accordion({
        wrap: $('.navigation'),
        item: $('.menu > .menu-item'),
        target: '.menu > .menu-item > a',
        contents: $('.menu .menu-item .sub-menu'),
        contentStr: '.menu .menu-item .sub-menu',
        hasClassSub: 'sub-menu'
      });
      accordion.handleAccordion();
    } else {
      /* the viewport is more than than 600 pixels wide */
      if ($('.navigation .menu').length) {
        // initialise plugin
        navigation = $('.navigation .menu').superfish();
      }
    }
  }
  screenSwitchDevice(mql);
  mql.addListener(screenSwitchDevice);
  //==================================================
  //▼ Only processed once when loading the screen end
  //==================================================
  var timer = false;
  $(window).resize(function() {
    if (timer !== false) {
      clearTimeout(timer);
    }
    timer = setTimeout(function() {
      if ($('.bussiness-map').length) {
        min_height_content();
      }
    }, 100);
  });

})(jQuery);