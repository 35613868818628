import $ from "jquery";
import Swal from 'sweetalert2';
import { EnumResponseStatus, EnumStatus, EnumWebOption } from "./enum";
import validate from 'jquery-validation';

import Handlebars from 'handlebars';
import MetaTagTemplate from "../templates/meta_tag.hbs";
const MetaTagFunc = Handlebars.compile(MetaTagTemplate);


class __Utils {
    constructor() {
        // this.g_LoadPanel = $('<div />').appendTo('body').dxLoadPanel({
        //     // position: { of: "#employee" },
        //     visible: false,
        //     showIndicator: true,
        //     showPane: true,
        //     shading: true,
        //     message: 'Đang tải...',
        //     closeOnOutsideClick: false,
        //     onShown: function () {
        //     },
        //     onHidden: function () {
        //     }
        // }).dxLoadPanel("instance");
    }

    showProgress(msg) {
        var root = this;
        root.hideProgress();
        Swal.fire({
            title: msg || 'Đang xử lý....',
            html: '<div class="loader-upload text-center"><div>',
            showConfirmButton: false,
            allowOutsideClick: false
        });
        root._showing = true;
    }

    bindMetaTag(forMetaTag) {
        if (!forMetaTag) {
            forMetaTag = {
                url_path: window.location.href,
                title: EnumWebOption.WebTitle,
                description: EnumWebOption.WebDescription,
                thumbnail: "",
                author: ""
            }
        }
        $("head").prepend(MetaTagFunc(forMetaTag));
    }

    hideProgress() {
        var root = this;
        if (root._showing) {
            $('.swal2-container.swal2-center.swal2-backdrop-show').remove();
            Swal.close();
        }
        root._showing = false;
    }

    alert(message, title, status) {
        if (status) {
            if (status == EnumStatus.OK) status = EnumResponseStatus.SUCCESS;
            else if (status == EnumStatus.ERROR) status = EnumResponseStatus.ERROR;
        }
        this.hideProgress();
        return Swal.fire(title || 'Thông báo', message, status || EnumResponseStatus.INFO);
    }
    warning(message) {
        this.hideProgress();
        return Swal.fire('Cảnh báo', message, EnumResponseStatus.WARNING);
    }
    alertOK(msg) {
        this.hideProgress();
        return Swal.fire("Thành công", msg, EnumResponseStatus.SUCCESS);
    }
    error(message, title) {
        this.hideProgress();
        return Swal.fire(title || "Thất bại", message || "Đã có lỗi xảy ra!\nVui Lòng thử lại sau!", EnumResponseStatus.ERROR);
    }

    confirm(title, confirmTxt) {
        this.hideProgress();
        return Swal.fire({
            title: title,
            icon: EnumResponseStatus.WARNING,
            showCancelButton: true,
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
            cancelButtonText: 'Hủy',
            confirmButtonText: confirmTxt || 'Xóa'
        });
    }

    disableMap(map) {
        map.dragging.disable();
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        if (map.tap) map.tap.disable();
        $(map._container).css('cursor', 'default');
    }
    enableMap(map) {
        map.dragging.enable();
        map.touchZoom.enable();
        map.doubleClickZoom.enable();
        map.scrollWheelZoom.enable();
        map.boxZoom.enable();
        map.keyboard.enable();
        if (map.tap) map.tap.enable();
        $(map._container).css('cursor', 'grab');
    }


    uuidv4() {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    }

    dateToString(date) {
        var mm = date.getMonth() + 1;
        var dd = date.getDate();
        return [
            (dd > 9 ? '' : '0') + dd,
            (mm > 9 ? '' : '0') + mm,
            date.getFullYear()
        ].join('-');
    }

    buildFormData(formData, data, parentKey) {
        var root = this;
        if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
            Object.keys(data).forEach(key => {
                let k = root._isFileList(data) ? parentKey : `${parentKey}[${key}]`;
                root.buildFormData(formData, data[key], parentKey ? k : key);
            });
        } else {
            const value = data == null ? '' : data;
            formData.append(parentKey, value);
        }
    }
    _isFileList(data) {
        return data && data[0] && (data[0] instanceof File);
    }
    jsonToFormData(data) {
        var root = this;
        const formData = new FormData();
        root.buildFormData(formData, data);
        return formData;
    }
    formDataToJson(formData) {
        return Object.fromEntries(formData);
    }

    ajaxPostFileAwait(option) {
        this.showProgress();
        let xhr = $.ajax({
            url: option.url,
            type: 'POST',
            data: option.formData,
            cache: false,
            async: false,
            dataType: 'json',
            processData: false,
            contentType: false,
            success: (response, textStatus, jqXHR) => {
                this.hideProgress();
                if (option.success)
                    option.success(response, textStatus, jqXHR);
            },
            error: (jqXHR, textStatus, errorThrown) => {
                this.hideProgress();
                if (option.error)
                    option.error(jqXHR, textStatus, errorThrown);
            }
        }).responseText;
        return JSON.parse(xhr);
    }

    ajaxPost(option) {
        this.rectifyFields(option.data)
        $.ajax({
            url: option.url,
            type: 'post',
            contentType: 'application/json; charset=utf8;',
            dataType: "json",
            data: JSON.stringify(option.data),
            success(response) {
                if (option.success)
                    option.success(response);
            },
            error(e) {
                if (option.error)
                    option.error(e);
            },
        });
    }
    rectifyFields(obj) {
        if (obj && typeof obj === "object") {
            //this.removeEmptyFields(obj);
            Object.keys(obj).forEach(key => {
                if (obj[key]) {
                    if (typeof obj[key] === "object")
                        this.rectifyFields(obj[key]); // recurse
                    else if (obj[key] == null)
                        delete obj[key]; // delete
                    else if (typeof obj[key] === "string") {
                        if (typeof obj[key] !== "number" && !isNaN(Number(obj[key])))
                            obj[key] = Number(obj[key]);
                    } else if (Array.isArray(obj[key]) && obj[key].length > 0) {
                        obj[key].forEach(rectifyFields);
                    }
                }
            });
        }
    }

    removeEmptyFields(obj) {
        if (obj && typeof obj === "object")
            Object.keys(obj).forEach(key => {
                if (obj[key] && typeof obj[key] === "object")
                    this.removeEmptyFields(obj[key]); // recurse
                else if (obj[key] == null) delete obj[key]; // delete
                else if (typeof obj[key] === "string") {
                    if (obj[key].trim() === "") delete obj[key];
                    else obj[key] = obj[key].trim();
                }
            });
    }
    getListPage(currentPage, totalPage, showPageNumber) {
        let listPage = [];

        let minPage, maxPage;

        currentPage = parseInt(currentPage);
        totalPage = parseInt(totalPage);
        showPageNumber = parseInt(showPageNumber);

        if (totalPage < showPageNumber) {
            minPage = 1;
            maxPage = totalPage;
        } else {
            var floor = Math.floor((totalPage - currentPage + 1) / showPageNumber);

            if (floor == 0) {
                minPage = totalPage - showPageNumber + 1;
                maxPage = totalPage;
            }
            else {
                minPage = currentPage > 1 ? currentPage - 1 : currentPage;
                maxPage = minPage + showPageNumber - 1;
            }
        }
        for (let i = minPage; i <= maxPage; i++) {
            listPage.push(i);
        }

        return listPage;
    };
    pagination(currentPage, totalPage, showPageNumber, container) {
        let listPage = this.getListPage(currentPage, totalPage, showPageNumber);
        let page = currentPage;
        container.find("li").remove();
        container.append(`<li class="prev ${page === 1 ? 'disabled' : ''}">
                                <a href="javascript:;" tabindex="-1">
                                    <span class="prev-arrow">&nbsp;</span>
                                </a>
                            </li>`);
        $.each(listPage, function (i, item) {
            if (item == page) {
                let htmlPageNumber = `<li class="active"><a href="#" class="page-number" id="page_number_${item}" data-id="${item}">${item}</a></li>`;
                container.append(htmlPageNumber);
            }
            else {
                let htmlPageNumber = `<li><a href="#" class="page-number" data-id="${item}" id="page_number_${item}">${item}</a></li>`;
                container.append(htmlPageNumber);
            }
        });
        container.append(`<li class="next ${page === totalPage ? 'disabled' : ''}">
                                <a href="javascript:;">
                                    <span class="next-arrow">&nbsp;</span>
                                </a>
                            </li>`);
    };
    getURLParameter(paramName) {
        let searchString = window.location.search.substring(1),
            i, val, params = searchString.split("&");

        for (let i = 0; i < params.length; i++) {
            val = params[i].split("=");
            if (val[0] == paramName) {

                return decodeURIComponent(val[1]);
                //return unescape(val[1]);
            }
        }
        return null;
    }
    postDownload(url, params) {
        let xhr = new XMLHttpRequest();
        xhr.open('POST', url, true);
        xhr.responseType = 'arraybuffer';
        xhr.onloadstart = () => {
            this.showProgress();
        };
        xhr.onload = function () {
            if (this.status === 200) {

                var filename = "";
                var disposition = xhr.getResponseHeader('Content-Disposition');
                if (disposition && disposition.indexOf('attachment') !== -1) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                    var matches = filenameRegex.exec(disposition);
                    if (matches != null && matches[1]) filename = matches[1].replace(/['"]/g, '');
                }
                var type = xhr.getResponseHeader('Content-Type');

                var blob;
                if (typeof File === 'function') {
                    try {
                        blob = new File([this.response], filename, { type: type });
                    } catch (e) { /* Edge */ }
                }
                if (typeof blob === 'undefined') {
                    blob = new Blob([this.response], { type: type });
                }

                if (typeof window.navigator.msSaveBlob !== 'undefined') {
                    // IE workaround for "HTML7007: One or more blob URLs were revoked by closing the blob for which they were created. These URLs will no longer resolve as the data backing the URL has been freed."
                    window.navigator.msSaveBlob(blob, filename);
                } else {
                    var URL = window.URL || window.webkitURL;
                    var downloadUrl = URL.createObjectURL(blob);

                    if (filename) {
                        // use HTML5 a[download] attribute to specify filename
                        var a = document.createElement("a");
                        // safari doesn't support this yet
                        if (typeof a.download === 'undefined') {
                            window.location = downloadUrl;
                        } else {
                            a.href = downloadUrl;
                            a.download = filename;
                            document.body.appendChild(a);
                            a.click();
                            a.remove();
                        }
                    } else {
                        window.location = downloadUrl;
                    }

                    setTimeout(function () { URL.revokeObjectURL(downloadUrl); }, 100); // cleanup
                }
                $("body").loader("hide");
            }
        };
        xhr.onloadend = () => {
            this.hideProgress();
        };
        xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        xhr.send($.param(params));
    }

    validateForm(options) {
        options.form.validate({
            errorElement: 'span', //default input error message container
            errorClass: 'help-block help-block-error text-danger invalid-error is-invalid', // default input error message class
            errorPlacement: function (error, element) {
                // Add the `help-block` class to the error element
                error.addClass("help-block");
                error.addClass("invalid-error");
                error.addClass("text-danger");

                if (element.prop("type") === "checkbox") {
                    error.insertAfter(element.parent("label"));
                } else if (element.prop("type") === "radio") {
                    element.closest('.form-group').append(error);
                } else {
                    error.insertAfter(element);
                }
            },
            focusInvalid: true, // do not focus the last invalid input
            ignore: "", // validate all fields including form hidden input
            rules: options.rules,
            messages: options.msg,
            invalidHandler: function (event, validator) { //display error alert on form submit
            },
            highlight: function (element) { // hightlight error inputs
                $(element).removeClass("has-success is-valid").addClass('has-error is-invalid'); // set error class to the control group
            },
            unhighlight: function (element) { // revert the change done by hightlight
                $(element).removeClass('has-error is-invalid').addClass("has-success "); // set error class to the control group
            },
            success: function (label, element) {
                //var icon = $(element).parent('.input-icon').find('i');
                $(element).closest('.form-group').removeClass('has-error').addClass(
                    'has-success'); // set success class to the control group
                //icon.removeClass("fa-warning").addClass("fa-check");
            },
            beforeSubmit: function (validate) {
                if (options.beforeSubmit)
                    return options.beforeSubmit(validate);
            },
            submitHandler: function (form) {
                return options.submit(form);
            }
        });
    }
    getFormDataAsJsonObject($form) {
        var unIndexedArray = $form.serializeArray();
        var indexedArray = {};

        $.map(unIndexedArray, function (n, i) {
            indexedArray[n["name"]] = n["value"];
        });

        return indexedArray;
    }
    refreshForm(form) {
        form.trigger('reset');
        form.find(`input:not('[type=radio]'),textarea`).val('');
        form.find('span.help-block.help-block-error').remove();
        form.find('.form-group').removeClass('has-success has-error');
        form.find(`input[type=radio]`).prop('checked', false);
        form.find(`select option`).prop('selected', false);
        form.find(`select option[value=0]`).prop('selected', true);
        form.find('.edit-hidden').show();
        form.find('.detail-hidden').show();
        form.find('input').prop('disabled', false);
        form.find('input,textarea').prop('readonly', false);
        form.find('select').attr('readonly', false);
        form.find('select').val('0');
        form.find('select').trigger('change');
    }
    validateFormAlert(options) {
        let parent = options.parent || '.form-group';
        let _$form = typeof options.form == 'string' ? $(options.form) : options.form;
        let _$parent = _$form.find(`.row:not('.edit-hidden') ${parent}[required]`);

        let $ele_email = _$form.find(`${parent} input[type=email]`);

        for (var i = 0; i < $ele_email.length; i++) {
            let $ele = $($ele_email[i]);
            if ($ele.val().indexOf('@') == -1) {
                this.alert('Vui lòng nhập đúng định dạng email', 'Lỗi', EnumStatus.ERROR);
                return false;
            }
        }

        let $eles = _$parent.find(`input:not('[name=id],[type=radio],[type=email],[type=password]'),select,textarea`);
        for (var i = 0; i < $eles.length; i++) {
            var msg = 'Vui lòng ';
            let $ele = $($eles[i]);
            if ($ele.val() == '' || $ele.val() == undefined || ($ele.prop('tagName').toLowerCase() == 'select' && $ele.val() == '0')) {
                if ($ele.prop('type') == 'text' || $ele.prop('tagName').toLowerCase() == 'textarea') {
                    msg += 'nhập ';
                } else if ($ele.prop('type') == 'radio' || $ele.prop('tagName').toLowerCase() == 'select') {
                    msg += 'chọn ';
                }
                msg += $ele.closest(parent).data('title').toLowerCase().replace(':', '');
                this.alert(msg, 'Lỗi', EnumStatus.ERROR);
                return false;
            }
        }
        return true;
    }

    replaceSizeMedia(src, size) {
        let src_resize = "";
        if (src.includes("media") == true && src.includes("no-image.png") == false) {
            src_resize = src.replace("media", size);
        } else {
            src_resize = src;
        }
        return src_resize;
    }

    //pagination(currentPage, totalPage, showPageNumber, container) {
    //    let listPage = [];

    //    let page = currentPage;
    //    if (totalPage < showPageNumber) {
    //        for (let i = 1; i <= totalPage; i++) {
    //            listPage.push(i);
    //        }
    //    } else {
    //        let minPage;
    //        if (currentPage % showPageNumber == 0) {
    //            minPage = page - 4;

    //        } else {
    //            if (parseInt(page / showPageNumber) == 0) {
    //                minPage = 1;
    //            }
    //            else {
    //                minPage = parseInt(page / showPageNumber) * showPageNumber + 1;
    //            }
    //        }
    //        let maxPage = parseInt(minPage) + 4;
    //        if (maxPage >= totalPage) {
    //            maxPage = totalPage;
    //        }
    //        for (let i = minPage; i <= maxPage; i++) {
    //            listPage.push(i);
    //        }
    //    }
    //    //return listPage;
    //    container.find("li").remove();
    //    container.append(`<li class="prev ${currentPage === 1 ? "disabled" : ""}">
    //                            <a href="javascript:;" tabindex="-1">
    //                                <span class="prev-arrow">&nbsp;</span>
    //                            </a>
    //                        </li>`);
    //    $.each(listPage, function (i, item) {
    //        if (item == page) {
    //            let htmlPageNumber = `<li class="active"><a data-id="${item}">${item}</a></li>`;
    //            container.append(htmlPageNumber);
    //        }
    //        else {
    //            let htmlPageNumber = `<li class="page-number" data-id="${item}" ><a href="javascript:;" data-id="${item}">${item}</a></li>`;
    //            container.append(htmlPageNumber);
    //        }
    //    });
    //    container.append(`<li class="next ${currentPage === totalPage ? "disabled" : ""}">
    //                            <a href="javascript:;">
    //                                <span class="next-arrow">&nbsp;</span>
    //                            </a>
    //                        </li>`);
    //};
}

const Utils = new __Utils();

export default Utils;